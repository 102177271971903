import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import moment from 'moment';
import { opcionesEsCita } from './orderFieldsYupValidation';

import axiosClient from '../../../config/axios.js';

import { es } from 'date-fns/locale';
import { Panel, PanelBody } from '../../../components/panel/panel.jsx';
import { Col, Row } from 'react-bootstrap';
import { useAuth } from '../../../context/AuthContext';



import { useReactToPrint } from 'react-to-print';

import { ComponentToPrint } from './orderPrint/ComponentToPrint';

function OrderTable({ orderStatusCode }) {
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [baseUser, setBaseUser] = useState('');
    const [insurance, setInsurance] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('id'); // Campo de ordenamiento predeterminado
    const [sortDirection, setSortDirection] = useState('desc'); // Sentido de orden predeterminado
    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [insurers, setInsurers] = useState([]);
    const [idOrder, setIdOrder] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const { userId } = useAuth();

    const modalRefPrint = useRef();
    const [modalInstance, setModalInstance] = useState(null);

    const formatOperador = (orderOperators) => {
        const formatted = orderOperators.map((item) => {
            return {
                value: item?.User.id,
                label: item?.User.name + ' ' + item?.User.first_surname + (item?.User.second_surname ? (' ' + item?.User.second_surname) : ''),
                actividad: item?.actividad ? item?.actividad : '',
                encargado: item?.encargado
            };
        });
        return formatted;
    }

    const formatMontacargas = (orderLiftTrucks) => {
        const formatted = orderLiftTrucks.map((item) => {
            return {
                value: item?.LiftTruck.id,
                label: item?.LiftTruck.lift_truck_number + ' ' + item?.LiftTruck.description
            };
        });
        return formatted;
    }

    const formatVehiculosAsistencia = (orderAssistanceVehicles) => {

        const formatted = orderAssistanceVehicles.map((item) => {
            return {
                value: item?.assistance_vehicle_id,
                label: item?.AssiVehi.VehicleModel?.VehicleType?.vehicle_type_name + ' ' + item?.AssiVehi.VehicleModel?.Brand?.brand_name + ' ' + item?.AssiVehi.VehicleModel?.model_name + ' (' + item?.AssiVehi.placas + ')',
            };
        });
        return formatted;
    }


    const formatAmounts = (amounts) => {
        const formatted = amounts.map((item) => {
            return {
                id: item?.id,
                monto_extra_descripcion: item?.description,
                monto_extra_monto: item?.amount
            };
        });

        return formatted;
    }

    const formatOrderLocations = (order_locations) => {
        //console.log("order_locations edit", order_locations);
        const formatted = order_locations.map((order_location) => {

            return {
                id: order_location.id,
                index: order_location.index,
                cuadro_busqueda: order_location.cuadro_busqueda,
                direccion: order_location.direccion,
                referencia: order_location.referencia,
                latitude: order_location.latitude,
                longitude: order_location.longitude,
                location_rate_id: order_location.location_rate,
                inside_polygon_local_rate: order_location.inside_polygon_local_rate,
                removable: true
            };
        });

        return formatted;
    }

    const formatVehicle = (vehicles) => {
        const formatted = vehicles.map((item) => {
            return {
                id: item?.id,
                vehicle_type_id: {
                    value: item?.VehicleModel?.VehicleType?.id,
                    label: item?.VehicleModel?.VehicleType?.vehicle_type_name,
                },
                brand_id: {
                    value: item?.VehicleModel?.Brand?.id,
                    label: item?.VehicleModel?.Brand?.brand_name,
                },
                vehicle_model_id: {
                    value: item?.VehicleModel?.id,
                    label: item?.VehicleModel?.model_name,
                },
                anio: { value: item?.anio, label: item?.anio },
                color_id: {
                    value: item?.Color?.id,
                    label: item?.Color?.color_name,
                    color: item?.Color?.color_code,
                    color_contrast: item?.Color?.color_contrast,
                },
                observaciones: item?.observaciones,
                placas: item?.placas,
                numero_serie: item?.numero_serie,
                shielding_type_id: item?.ShieldingType && item?.ShieldingType?.id ? {
                    value: item?.ShieldingType?.id,
                    label: item?.ShieldingType?.shielding_type,
                } : '',
                carga_kg: item?.carga_kg ? item?.carga_kg : '',
                registrar_volumen: item?.registrar_volumen,
                volumen_largo: item?.volumen_largo,
                volumen_ancho: item?.volumen_ancho,
                volumen_altura: item?.volumen_altura,
                volumen_m3: item?.volumen_m3,
                estado_llantas_giran: item?.estado_llantas_giran === true ? true : (item?.estado_llantas_giran === false ? false : ''),
                estado_volante_gira: item?.estado_volante_gira === true ? true : (item?.estado_volante_gira === false ? false : ''),
                estado_pie_calle: item?.estado_pie_calle === true ? true : (item?.estado_pie_calle === false ? false : ''),
                estado_neutral: item?.estado_neutral === true ? true : (item?.estado_neutral === false ? false : ''),
                estado_en_cajon: item?.estado_en_cajon === true ? true : (item?.estado_en_cajon === false ? false : ''),
                asistencia_paso_corriente: item?.asistencia_paso_corriente === true ? true : (item?.asistencia_paso_corriente === false ? false : ''),
                asistencia_combustible: item?.asistencia_combustible === true ? true : (item?.asistencia_combustible === false ? false : ''),
                asistencia_cambio_neumatico: item?.asistencia_cambio_neumatico === true ? true : (item?.asistencia_cambio_neumatico === false ? false : ''),
                asistencia_cerrajeria: item?.asistencia_cerrajeria === true ? true : (item?.asistencia_cerrajeria === false ? false : ''),
            };
        });

        return formatted;
    }

    useEffect(() => {
        axiosClient.get('/users/allUsers').then(response => {

            const formattedOptions = response.data.data.map(user => ({
                value: user.id,
                label: (user.name + ' ' + user.first_surname + ' ' + (user.second_surname ? user.second_surname : '')).trim(),
            }));
            const userIdInt = parseInt(userId ?? "-1");
            const currentUser = formattedOptions.find(user => user.value === userIdInt);
            //console.log("usuarios", formattedOptions);
            //console.log("userId", userId);
            setUsuarios(formattedOptions);
            setBaseUser(currentUser);
            //console.log("currentUser?.value", currentUser);
        }).catch(error => {
            console.error('Error fetching users:', error);
        });


        axiosClient.get('/insurers/all').then(response => {

            const formattedOptions = response.data.data.map(insurance => ({
                value: insurance.id,
                label: insurance.insurance_name
            }));

            setInsurers(formattedOptions);

        }).catch(error => {
            console.error('Error fetching insurers:', error);
        });

    }, []);

    useEffect(() => {

        //console.log("useEffect on order id", idOrder);

        if (idOrder) {
            axiosClient.get(`/orders/${idOrder}`).then(response => {
                //console.log("response order data", response);
                const {
                    id,
                    fecha_hora_reporte,
                    fecha_hora_termino,
                    folio,
                    current_order_status,
                    es_cita,
                    fecha_hora_cita,
                    fecha_hora_alerta_cita,

                    expediente,
                    reporte,
                    PaymentType,
                    fecha_hora_contacto,
                    fecha_hora_envio,
                    fecha_hora_llegada,
                    fecha_hora_entrega,

                    //información del servicio ,
                    grua,
                    grua_vial,
                    auxilio_vial,
                    montacarga,
                    corresponsalia,
                    taxi,
                    tipo_servicio_observaciones,

                    //Datos de los bienes ,
                    carta_porte,

                    bienes_observaciones,

                    //Datos de contacto y seguimiento,
                    ClientType,
                    Insurance,
                    autorizacion_cabina,
                    empresa,
                    cliente_vip,
                    beneficiario_servicio,
                    telefono_principal,
                    numero_poliza,
                    numero_siniestro,
                    numero_folio,
                    monto_aseguradora_paga,
                    excedente_asegurado,
                    datos_contacto_seguimiento_observaciones,

                    //Ubicación de la atención ,
                    ServiceLocationType,
                    edit_locations_finished,

                    //Cálculo de montos ,
                    km,
                    Crane,
                    base,

                    grua_tarifa_kilometro,
                    grua_subtotal,
                    grua_banderazo,
                    grua_casetas,

                    aux_vial_tarifa_kilometro,
                    aux_vial_subtotal,
                    aux_vial_banderazo,
                    aux_vial_casetas,

                    montacargas_costo_hora,
                    montacargas_horas,
                    montacargas_subtotal,
                    montacargas_traslado,

                    corresponsalia_subtotal,
                    corresponsalia_observaciones,
                    taxi_subtotal,
                    monto_blindaje,
                    monto_carga_volumen,
                    servicio_muerto_monto_penalizacion,

                    manejar_precios_netos,
                    manejar_tarifas_establecidas,
                    venta_publico,
                    subtotal,
                    iva,
                    total,
                    OrderLiftTrucks,
                    OrAssisVehi,
                    OrderOperators,
                    OrderLocations,
                    OrderAmounts,
                    Vehicles,
                } = response.data;

                setOrderData({
                    id: id,
                    fecha_hora_reporte: fecha_hora_reporte,
                    folio: folio,
                    current_order_status_id: current_order_status,
                    es_cita: opcionesEsCita.find(option => option.value === es_cita),
                    //es_cita: es_cita ? { value: true, label: 'PROGRAMAR CITA' } : { value: false, label: 'ATENCIÓN INMEDIATA' },
                    fecha_hora_cita: fecha_hora_cita ? moment(fecha_hora_cita).toDate() : '',
                    fecha_hora_alerta_cita: '', //se deja a propósito debido a que se calcula al cambiar alerta_antes_hora_cita //fecha_hora_alerta_cita ? moment(fecha_hora_alerta_cita).toDate() : '',
                    alerta_antes_hora_cita: fecha_hora_cita && fecha_hora_alerta_cita ? moment().startOf('day').add(moment.duration(moment(fecha_hora_cita).diff(moment(fecha_hora_alerta_cita)))) : '',
                    expediente: expediente ? expediente : '',
                    reporte: reporte ? reporte : '',
                    tipo_pago_id: PaymentType ? {
                        value: PaymentType ? PaymentType.id : '',
                        label: PaymentType ? PaymentType.payment_type_name : '',
                        code: PaymentType ? PaymentType.payment_type_code : ''
                    } : '',

                    fecha_hora_contacto: fecha_hora_contacto ? moment(fecha_hora_contacto).toDate() : '',
                    fecha_hora_envio: fecha_hora_envio ? moment(fecha_hora_envio).toDate() : '',
                    fecha_hora_llegada: fecha_hora_llegada ? moment(fecha_hora_llegada).toDate() : '',
                    fecha_hora_entrega: fecha_hora_entrega ? moment(fecha_hora_entrega).toDate() : '',
                    fecha_hora_termino: fecha_hora_termino ? moment(fecha_hora_termino).toDate() : '',
                    

                    //información del servicio ,
                    grua,
                    grua_vial,
                    auxilio_vial,
                    montacarga,
                    corresponsalia,
                    taxi,
                    tipo_servicio_observaciones,

                    //Datos de los bienes ,
                    carta_porte,

                    bienes_observaciones: bienes_observaciones ? bienes_observaciones : '',

                    //Datos de contacto y seguimiento,
                    client_type_id: {
                        value: ClientType.id,
                        label: ClientType.client_type,
                        client_type_code: ClientType.client_type_code
                    },
                    insurance_id: Insurance ? {
                        value: Insurance.id,
                        label: Insurance.insurance_name,
                    } : '',
                    autorizacion_cabina: autorizacion_cabina ? autorizacion_cabina : '',
                    empresa: empresa ? empresa : '',
                    cliente_vip,
                    beneficiario_servicio: beneficiario_servicio ? beneficiario_servicio : '',
                    telefono_principal: telefono_principal ? telefono_principal : '',
                    numero_poliza: numero_poliza ? numero_poliza : '',
                    numero_siniestro: numero_siniestro ? numero_siniestro : '',
                    numero_folio: numero_folio ? numero_folio : '',
                    excedente_asegurado: excedente_asegurado === 0 ? 0 : (excedente_asegurado ? excedente_asegurado : null),
                    monto_aseguradora_paga: monto_aseguradora_paga === 0 ? 0 : (monto_aseguradora_paga ? monto_aseguradora_paga : null),
                    datos_contacto_seguimiento_observaciones: datos_contacto_seguimiento_observaciones ? datos_contacto_seguimiento_observaciones : '',

                    //Ubicación de la atención ,
                    service_location_type_id: {
                        value: ServiceLocationType?.id,
                        label: ServiceLocationType?.service_location_type,
                        service_location_code: ServiceLocationType?.service_location_code
                    },
                    edit_locations_finished,

                    //Cálculo de montos ,
                    km,
                    crane_id: Crane !== null ? {
                        value: Crane.id,
                        label: Crane.crane_number + ' (' + Crane.CraneTypes.map(craneType => craneType.crane_type_name).join(', ') + ')',
                        crane_type_name: Crane.CraneTypes.map(craneType => craneType.crane_type_name).join(', ')
                    } : null,
                    base_id: base ? {
                        value: base.id,
                        label: (base.name + ' ' + base.first_surname + ' ' + (base.second_surname ? base.second_surname : '')).trim(),
                    } : '',


                    //Montos
                    grua_tarifa_kilometro: grua_tarifa_kilometro === 0 ? 0 : (grua_tarifa_kilometro ? grua_tarifa_kilometro : ''),
                    grua_subtotal: grua_subtotal === 0 ? 0 : (grua_subtotal ? grua_subtotal : ''),
                    grua_banderazo: grua_banderazo === 0 ? 0 : (grua_banderazo ? grua_banderazo : ''),
                    grua_casetas: grua_casetas === 0 ? 0 : (grua_casetas ? grua_casetas : ''),

                    aux_vial_tarifa_kilometro: aux_vial_tarifa_kilometro === 0 ? 0 : (aux_vial_tarifa_kilometro ? aux_vial_tarifa_kilometro : ''),
                    aux_vial_subtotal: aux_vial_subtotal === 0 ? 0 : (aux_vial_subtotal ? aux_vial_subtotal : ''),
                    aux_vial_banderazo: aux_vial_banderazo === 0 ? 0 : (aux_vial_banderazo ? aux_vial_banderazo : ''),
                    aux_vial_casetas: aux_vial_casetas === 0 ? 0 : (aux_vial_casetas ? aux_vial_casetas : ''),

                    montacargas_costo_hora: montacargas_costo_hora === 0 ? 0 : (montacargas_costo_hora ? montacargas_costo_hora : ''),
                    montacargas_horas: montacargas_horas === 0 ? 0 : (montacargas_horas ? montacargas_horas : ''),
                    montacargas_subtotal: montacargas_subtotal === 0 ? 0 : (montacargas_subtotal ? montacargas_subtotal : ''),
                    montacargas_traslado: montacargas_traslado === 0 ? 0 : (montacargas_traslado ? montacargas_traslado : ''),

                    corresponsalia_subtotal: corresponsalia_subtotal === 0 ? 0 : (corresponsalia_subtotal ? corresponsalia_subtotal : ''),
                    corresponsalia_observaciones: corresponsalia_observaciones ? corresponsalia_observaciones : '',
                    taxi_subtotal: taxi_subtotal === 0 ? 0 : (taxi_subtotal ? taxi_subtotal : ''),

                    monto_blindaje: monto_blindaje === 0 ? 0 : (monto_blindaje ? monto_blindaje : ''),
                    monto_carga_volumen: monto_carga_volumen === 0 ? 0 : (monto_carga_volumen ? monto_carga_volumen : ''),
                    servicio_muerto_monto_penalizacion: servicio_muerto_monto_penalizacion === 0 ? 0 : (servicio_muerto_monto_penalizacion ? servicio_muerto_monto_penalizacion : ''),

                    manejar_precios_netos: manejar_precios_netos ? manejar_precios_netos : false,
                    manejar_tarifas_establecidas: manejar_tarifas_establecidas ? manejar_tarifas_establecidas : false,
                    venta_publico,
                    subtotal,
                    iva,
                    total,

                    montacargas: formatMontacargas(OrderLiftTrucks),
                    vehiculos_asistencia: formatVehiculosAsistencia(OrAssisVehi),
                    operadores: formatOperador(OrderOperators),
                    montosExtras: formatAmounts(OrderAmounts),
                    vehiculos: formatVehicle(Vehicles),
                    order_locations: formatOrderLocations(OrderLocations)
                });

            }).catch(error => {
                console.error('Error fetching order data:', error);
            });
        }


    }, [idOrder]);



    const deleteOrder = id => {
        Swal.fire({
            title: '¿Está seguro que desea borrar la Carta de Servicio seleccionada?',
            text: "Una vez que se haya borrado la Carta de Servicio, no podrá recuperarla.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar Carta de Servicio',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/orders/${id}`).then(response => {

                    fetchData();
                    Swal.fire(
                        'Carta de Servicio borrada',
                        'La Carta de Servicio se ha borrado exitosamente',
                        'success'
                    )
                })

            }
        })
    }

    const fetchData = async (currentPage = 1, search = '', sortField, sortDirection, baseUser, insurance) => {
        try {

            //console.log("fetchData fetchData", insurance);

            const response = await axiosClient.get(`/orders/datatable`, {
                params: {
                    order_status_code: orderStatusCode,
                    start: (currentPage - 1) * rowsPerPage,
                    length: rowsPerPage,
                    search: search,
                    baseUserId: baseUser?.value,
                    insuranceId: insurance?.value,
                    sort: sortField,
                    dir: sortDirection,
                },
            });
            //console.log("response order table", response.data);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData(currentPage, searchValue, sortField, sortDirection, baseUser, insurance);
    }, [currentPage, rowsPerPage, sortField, sortDirection, orderStatusCode, baseUser, insurance]);

    const columns = [
        {
            name: 'Folio ', selector: (row, i) =>
                <>
                    <Row className='mb-2'>
                        <Col md="12">
                            <Link to={`/orders/edit/${row.id}`}>
                                {row.folio.toString().padStart(5, '0')}
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <button
                                className="btn btn-yellow btn-circle btn-icon"
                                onClick={() => { setIdOrder(row.id); handlePrintOrder(); }}
                            >
                                <span className='fa fa-print'></span>
                            </button>
                        </Col>
                    </Row>
                </>
            , sortable: true, sortField: 'folio', width: '6%'
        },
        {
            name: <>
                Fecha y Hora <br></br>
                de Registro
            </>,
            selector: (row, i) =>
                <>
                    {format(
                        new Date(row.fecha_hora_reporte),
                        'dd/MMM/yyyy',
                        { locale: es }
                    )}
                    <br></br>
                    {format(
                        new Date(row.fecha_hora_reporte),
                        'hh:mm a',
                        { locale: es }
                    )} {' '} por
                    <br></br>{(row?.base?.name + ' ' + row?.base?.first_surname + ' ' + (row?.base?.second_surname ? row?.base?.second_surname : '')).trim()}
                </>,

            sortable: true, sortField: 'fecha_hora_reporte',
            width: '12%'
        },
        {
            name: <>
                Última <br></br>
                actualización
            </>,
            selector: (row, i) =>
                <>
                    {format(
                        new Date(row.updatedAt),
                        'dd/MMM/yyyy',
                        { locale: es }
                    )}
                    <br></br>
                    {format(
                        new Date(row.updatedAt),
                        'hh:mm a',
                        { locale: es }
                    )}
                </>,

            sortable: true, sortField: 'updatedAt',
            width: '12%'
        },
        {
            id: 'order_status',
            omit: orderStatusCode
                ? true
                : false,
            name: <>
                Estatus <br></br>
                actual
            </>,
            selector: (row, i) =>
                <>
                    <span className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                        {row?.current_order_status?.order_status}
                    </span>

                </>,

            sortable: false, sortField: 'fecha_hora_reporte',
            width: '10%'
        },
        {
            name: <>
                Tipo de<br></br>
                Servicio
            </>,
            width: '10%',
            cell: row => {
                const badges = [];

                if (row.grua) {
                    badges.push(
                        <span key="servicio" className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            Grúa
                        </span>
                    );
                }

                if (row.auxilio_vial) {
                    badges.push(
                        <span key="auxilio_vial" className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            Auxilio Vial
                        </span>
                    );
                }

                if (row.montacarga) {
                    badges.push(
                        <span key="montacarga" className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            Montacarga
                        </span>
                    );
                }

                if (row.corresponsalia) {
                    badges.push(
                        <span key="corresponsalia" className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            Corresponsalía
                        </span>
                    );
                }

                if (row.taxi) {
                    badges.push(
                        <span key="taxi" className="ms-1 mb-1 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            Taxi
                        </span>
                    );
                }

                // Luego, puedes renderizar todos los badges en un solo elemento div
                return <div>{badges}</div>;
            },
        },
        {
            name: <>
                Tipo de<br></br>
                Cliente
            </>, selector: (row, i) =>
                <>
                    {row?.ClientType?.client_type}
                    {row?.Insurance?.insurance_name &&
                        <>
                            <br></br>
                            {row?.Insurance?.insurance_name}
                        </>
                    }
                </>

            , sortable: false, width: '11%'
        },
        {
            name: "Vehículo",
            cell: (row, i) => {
                const vehicles = row?.Vehicles || [];
                const vehicleInfo = vehicles.map(vehicle => {
                    if (vehicle?.VehicleModel) {
                        const { Brand, model_name } = vehicle.VehicleModel;
                        const color = vehicle.Color?.color_name || "";
                        const year = vehicle.anio || "";
                        return `${Brand?.brand_name ? Brand?.brand_name + ' ' : ''} ${model_name ? model_name + ', ' : ''} ${color ? color + ', ' : ''} ${year ? year + ', ' : ''}`;
                    } else {
                        return '';
                    }

                }).join(" | ");
                return `${vehicleInfo}`;
            },
            sortable: false,
            width: '11%'
        },
        {
            name: "Operador",
            cell: (row, i) => {
                const operators = row?.OrderOperators || [];
                const operatorInfo = operators.map(operator => {
                    const { name, first_surname, second_surname } = operator.User;
                    return `${name ? name + ' ' : ''} ${first_surname ? first_surname + ' ' : ''} ${second_surname ? second_surname : ''}`;
                }).join(" | ");
                return `${operatorInfo}`;
            },
            sortable: false,
            width: '11%'
        },
        {
            name: 'Expediente', selector: (row, i) =>
                <>
                    {row?.expediente}
                </>

            , sortable: true, sortField: 'expediente', width: '9%'
        },
        {
            name: <>
                Beneficiario<br></br>
                del Servicio
            </>, selector: (row, i) =>
                <>
                    {row.cliente_vip && (
                        <>
                            <span key="cliente_vip" className="mb-1 badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                Cliente VIP
                            </span>
                            <br></br>
                        </>
                    )}

                    {row.beneficiario_servicio}

                </>

            , sortable: true, sortField: 'beneficiario_servicio', width: '16%'
        },
        { name: 'Empresa', selector: (row, i) => row.empresa, sortable: true, sortField: 'empresa', width: '15%' },
        {
            name: 'Teléfono', selector: (row, i) =>
                <>
                    {row.telefono_principal}
                </>, sortable: true, sortField: 'telefono_principal', width: '11%'
        },
        /*{ name: 'Placas', selector: (row, i) => row.placas, sortable: true, sortField: 'placas' },*/
        {
            name: <>
                Fecha y Hora <br></br>
                de Contacto
            </>,
            omit: orderStatusCode
                ? false
                : true,
            selector: (row, i) =>
                <>
                    {row.fecha_hora_contacto && (
                        <>
                            {format(
                                new Date(row.fecha_hora_contacto),
                                'dd/MMM/yyyy',
                                { locale: es }
                            )}
                            <br></br>
                            {format(
                                new Date(row.fecha_hora_contacto),
                                'hh:mm a',
                                { locale: es }
                            )}
                        </>
                    )}

                </>,
            sortable: true, sortField: 'fecha_hora_contacto',
            width: '10%'
        },

    ];

    const customStyles = {
        headCells: {
            style: {
                color: '#202124',
                fontSize: '14px',
                backgroundColor: '#EEEEEE',
                border: 'none'
            },
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setLoading(true);
            setCurrentPage(1);
            fetchData(currentPage, searchValue, sortField, sortDirection, baseUser, insurance);
        }
    };

    const handlePrintOrder = () => {
        const modal = modalRefPrint.current;
        if (modal) {
            const modalInstance = new bootstrap.Modal(modal);
            setModalInstance(modalInstance);
            modalInstance.show();
        }
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });



    return (
        <>

            <div className="modal fade " ref={modalRefPrint}>

                <div className="modal-dialog modal-print">
                    <div className="modal-content ">



                        <Panel>
                            <PanelBody>
                                <Row>
                                    <Col md="12">
                                        {(idOrder && orderData) && (
                                            <button className="btn btn-yellow float-end" onClick={handlePrint}><span className='fa fa-print' /> Imprimir</button>
                                        )}
                                    </Col>
                                </Row>
                            </PanelBody>
                        </Panel>


                        <div ref={componentRef} id={'print'}>
                            {idOrder && orderData && (
                                <ComponentToPrint
                                    id={idOrder}
                                    orderData={orderData}
                                />
                            )}

                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive mb-3">

                <DataTable
                    title=""
                    className="table"
                    columns={columns}
                    data={data.data}
                    progressPending={loading}
                    sortServer
                    pagination
                    paginationServer
                    paginationPerPage={rowsPerPage}
                    paginationTotalRows={data.recordsFiltered}
                    paginationDefaultPage={currentPage}
                    subHeader
                    subHeaderWrap={false}
                    subHeaderAlign='center'
                    customStyles={customStyles}
                    pointerOnHover
                    paginationRowsPerPageOptions={[50, 100, 200, 500]}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Filas por página',
                        rangeSeparatorText: 'de',
                        selectAllRowsItem: false,
                    }}
                    noDataComponent="Sin información por mostrar"
                    progressComponent={
                        <Spinner animation="border" location="status">
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                    }
                    subHeaderComponent={

                        <div className='row vw-100'>
                            <div className='col-sm-4 col-md-4'>

                                <Select
                                    name='client_type_id'
                                    options={usuarios}
                                    value={baseUser}
                                    placeholder="Filtrar por usuario que registra la Orden"
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setBaseUser(e ? e : null);
                                    }}
                                />
                            </div>
                            <div className='col-sm-4 col-md-4'>

                                <Select
                                    name='insurance_id'
                                    options={insurers}
                                    value={insurance}
                                    placeholder="Filtrar por aseguradora"
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        //console.log("insurance seleccionada", e);
                                        setInsurance(e ? e : null);
                                    }}
                                />
                            </div>
                            <div className='col-sm-4 col-md-4 ml-auto'>
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        placeholder="Buscar..."
                                        value={searchValue}
                                        onChange={e => setSearchValue(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <div className="input-group-text btn btn-info"
                                        onClick={() => {
                                            setLoading(true);
                                            setCurrentPage(1);
                                            fetchData(currentPage, searchValue, sortField, sortDirection, baseUser, insurance); // Realizar búsqueda aquí
                                        }}><i className="fa fa-search"></i></div>
                                </div>
                            </div>
                        </div>


                    }
                    onChangePage={(page) => {
                        setCurrentPage(page);
                    }}
                    onSearch={(value) => {
                        setSearchValue(value);
                        setLoading(true);
                        setCurrentPage(1); // Resetea la página al hacer una búsqueda
                    }}
                    onChangeRowsPerPage={(newPerPage, page) => {
                        setRowsPerPage(newPerPage);
                        setCurrentPage(1); // Resetea la página al cambiar la cantidad de registros por página
                    }}
                    onSort={(column, sortDirection) => {
                        setSortDirection(sortDirection);
                        setSortField(column.sortField);
                        setLoading(true);
                        setCurrentPage(1); // Resetea la página al hacer una búsqueda
                    }}


                />
            </div>
        </>
    );
}

export default OrderTable;