import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import SqlViewTablaResultado from './sqlViewTablaResultado';
import axiosClient from '../../../config/axios.js';

function SqlView() {
    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleExecuteQuery = async () => {
        let response = null;

        const now = new Date();
        const formattedDateTime = now.toLocaleString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });

        try {
            response = await axiosClient.post('/system/query', { query });

            if (query.trim().toLowerCase().startsWith('select')) {
                setResult(
                    <>
                        <div>{`${formattedDateTime}`}</div>
                        <SqlViewTablaResultado datos={response.data} />
                    </>
                );

            } else {

                setResult(
                    `${formattedDateTime} - Query ejecutado correctamente. Filas afectadas: ${response.data}`
                );
            }
        } catch (error) {
            setResult(`${formattedDateTime}; Error: ${error} | ${error?.response?.data?.error}`);
        }
    };

    const handleDownload = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosClient.post('/system/dump', {}, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'backup.sql');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            setError('Error al descargar el dump de la base de datos.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Row className="mb-1">
                <Form.Group as={Col} md="12">
                    <Form.Label>Query</Form.Label>
                    <textarea
                        name="herramientas_observaciones"
                        className={`form-control`}
                        onChange={(e) => {
                            setQuery(e.target.value);
                            //setFieldValue('herramientas_observaciones', e.target.value);
                        }}
                        value={query}
                    ></textarea>

                </Form.Group>
            </Row>
            <Row className='mb-4'>
                <button type='button' className='btn btn-info' onClick={handleExecuteQuery}>Ejecutar</button>
            </Row>
            <Row>
                <div>{result}</div>
            </Row>


            <Row>
                <button onClick={handleDownload} type='button' className='btn btn-primary' disabled={loading}>
                    {loading ? 'Generando dump...' : 'Descargar Dump de Base de Datos'}
                </button>
            </Row>
        </>

    );
}

export default SqlView;